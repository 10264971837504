/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';

// @mui
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// components
import Logo from 'components/Logo';
import Link from 'components/Link/Link';
import SearchBar from 'components/Header/SearchBar';
import LanguagePicker from 'components/LanguagePicker';
import KycButton from 'components/Header/KycButton/KycButton';
import CopyrightText from 'components/Header/TopMenu/MobileTopMenu/CopyrightText';

// constant
import ROUTES from 'constants/routes';
import WP_ROUTES from 'constants/wordpress-routes';

// hooks
import useAuth from 'hooks/useAuth';
import useReferrals from 'hooks/useReferrals';

// utils
import { renderKycInfo } from 'utils/functions';

const styles = {
  headerRoot: {
    height: '100%',
    overflow: 'hidden',
  },
  headerUnautenticatedArea: {
    width: '120px',
    height: '42.45px',
    m: '0 auto',
  },
  searchBar: {
    flexGrow: 1,
    mt: -0.5,
  },
  creditLabel: {
    flexGrow: 1,
    textTransform: 'uppercase',
    boxShadow: 4,
    height: 27,
    borderRadius: 1,
    mt: -0.5,
    mx: 0,
    '@media screen and (min-width: 540px)': {
      mx: 10,
    },
  },
  headerAuthenticatedArea: {
    width: '100%',
    overflow: 'hidden',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    width: '120px',
    height: '42.45px',
    '@media screen and (max-width: 768px)': {
      ml: '16.65rem',
    },
    '@media screen and (max-width: 425px)': {
      ml: '7.54rem',
    },
    '@media screen and (max-width: 375px)': {
      ml: '6.21rem',
    },
    '@media screen and (max-width: 320px)': {
      ml: '4.75rem',
    },
  },
  languagePicker: {
    width: '56px',
    height: '56px',
    alignContent: 'center',
    pt: 0.3,
    mr: 0.25,
  },
};

const mobileTopMenuHomeDefaultPropTopMenu = [];

const MobileTopMenuHome = ({
  topMenu = mobileTopMenuHomeDefaultPropTopMenu,
  handleTopMenuHomeClick,
  handleTopMenuSandboxClick,
  isLoading = false,
}) => {
  const { isAuthenticated, user, userCredit } = useAuth();

  // Use the custom hook for referrals
  const { queryParamsString } = useReferrals();

  return (
    <Stack sx={styles.headerRoot}>
      {/* Header */}
      <Stack direction="row" sx={styles.headerAuthenticatedArea}>
        <Box sx={styles.logo}>
          <Logo variant="full" width={145} height={51.66} />
        </Box>
        <Box sx={styles.languagePicker}>
          <LanguagePicker />
        </Box>
      </Stack>

      {renderKycInfo(user, userCredit) && (
        <ListItem>
          <KycButton sx={styles.creditLabel} />
        </ListItem>
      )}
      <ListItem>
        <SearchBar sx={styles.searchBar} />
      </ListItem>
      <Divider />

      {/* Menu */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <List>
          {isLoading
            ? [1, 2, 3, 4, 5].map((i) => (
                <ListItem key={i}>
                  <Skeleton variant="text" animation="wave" width="100%" height="32px" />
                </ListItem>
              ))
            : topMenu.map((item) => {
                const shouldNotRender = !item.availableForAnonymousUser && !isAuthenticated;
                if (shouldNotRender) return undefined;
                return item.__component === 'ui.edit' ? (
                  <ListItem disablePadding key={item.link.id}>
                    <ListItemButton component={Link} href={item.link.url} passHref>
                      <ListItemText
                        primary={
                          <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                            {item.link.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <ListItem disablePadding key={item.id}>
                    <ListItemButton
                      onClick={() =>
                        item.__component === 'ui.sandbox-menu-section'
                          ? handleTopMenuSandboxClick(
                              item.label,
                              item.CategoriesColumn,
                              item.ProductsColumn,
                              item.WholesalersColumn,
                              item.BannersColumn
                            )
                          : handleTopMenuHomeClick(
                              item.label,
                              item.categories,
                              item.wholesalers,
                              item.banners,
                              item.link.url
                            )
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                            {item.label}
                          </Typography>
                        }
                      />
                      <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                        <ChevronRightIcon color="primary" />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                );
              })}
        </List>
      </Box>

      {/* Buttons Area    */}
      {!isAuthenticated && (
        <>
          <Divider />
          <List sx={{ my: -0.5 }}>
            <ListItem sx={{ justifyContent: 'space-between' }}>
              <Link href={ROUTES.auth.login} passHref>
                Login
              </Link>
              <Link href="/signup/wholesaler/" passHref>
                Sell On Creoate
              </Link>
            </ListItem>

            <ListItem>
              <Button
                variant="contained"
                fullWidth
                component={Link}
                href={`${WP_ROUTES.register}?${queryParamsString}`}
                passHref
              >
                Register to buy
              </Button>
            </ListItem>
          </List>
        </>
      )}

      {/* Copiright Area */}
      <Divider />
      <List>
        <ListItem>
          <ListItemText sx={{ my: -0.5 }} primary={<CopyrightText />} />
        </ListItem>
      </List>
    </Stack>
  );
};

export default MobileTopMenuHome;

MobileTopMenuHome.propTypes = {
  topMenu: PropTypes.arrayOf(
    PropTypes.shape({
      availableForAnonymousUser: PropTypes.bool,
      __component: PropTypes.string,
      link: PropTypes.shape({
        url: PropTypes.string,
        label: PropTypes.string,
      }),
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          slug: PropTypes.string,
          subcategories: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              name: PropTypes.string,
              slug: PropTypes.string,
            })
          ),
        })
      ),
      wholesalers: PropTypes.arrayOf(
        PropTypes.shape({
          brand_name: PropTypes.string,
          slug: PropTypes.string,
        })
      ),
      banners: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          image: PropTypes.shape({
            id: PropTypes.number.isRequired,
            url: PropTypes.string.isRequired,
          }),
          link: PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string,
            url: PropTypes.string,
          }),
        })
      ),
    })
  ),
  handleTopMenuHomeClick: PropTypes.func.isRequired,
  handleTopMenuSandboxClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
