import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useReducer } from 'react';

/** MUI */
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled, useTheme } from '@mui/material/styles';

/** Hooks */
import useCurrency from 'hooks/useCurrency';
import useLanguage from 'hooks/useLanguage';

/** Components */

/** Const  */
import currencies from 'constants/currencies';

import { CurrencyExchange } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { log } from 'utils/functions';
import useResponsive from 'hooks/useResponsive';

const styles = {
  language: {
    width: '2rem',
    position: 'absolute',
    top: -8,
    right: { sm: '2rem', md: '4.5rem', lg: '4.5rem', xl: '4.5rem' },
    zIndex: 1,
  },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const bootstrapDialogTitleDefaultPropChildren = {};

const BootstrapDialogTitle = ({ children = bootstrapDialogTitleDefaultPropChildren, onClose, ...other }) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const reducerActions = {
  UPDATE_CURRENCY: 'update_currency',
  UPDATE_LANGUAGE: 'update_language',
  UPDATE_OPEN: 'update_open',
  UPDATE_MANY: 'update_all',
};

const initialState = {
  selectedCurrency: '',
  selectedLanguage: '',
  open: false,
};

const languagePickerReducer = (state, action) => {
  switch (action.type) {
    case reducerActions.UPDATE_CURRENCY:
      return { ...state, selectedCurrency: action.selectedCurrency };
    case reducerActions.UPDATE_LANGUAGE:
      return { ...state, selectedLanguage: action.selectedLanguage };
    case reducerActions.UPDATE_OPEN:
      return { ...state, open: action.open };
    case reducerActions.UPDATE_MANY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const LanguagePicker = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { language, setLanguage } = useLanguage();
  const { currency, setCurrency } = useCurrency();
  const [{ selectedCurrency, selectedLanguage, open }, dispatch] = useReducer(languagePickerReducer, initialState);
  const isDesktop = useResponsive('up', 'md');

  useEffect(() => {
    dispatch({
      type: reducerActions.UPDATE_MANY,
      payload: { selectedLanguage: language?.name, selectedCurrency: currency?.name },
    });
  }, [currency?.name, language?.name]);

  const handleClickOpen = () => {
    dispatch({
      type: reducerActions.UPDATE_OPEN,
      open: true,
    });
  };

  const handleClose = () => {
    dispatch({
      type: reducerActions.UPDATE_OPEN,
      open: false,
    });
  };

  const handleChangeCurrency = (event) => {
    dispatch({ type: reducerActions.UPDATE_CURRENCY, selectedCurrency: event.target.value });
  };

  const handleSelection = () => {
    try {
      setCurrency(selectedCurrency);
      setLanguage(selectedLanguage);
      enqueueSnackbar('Changes sucessful', {
        variant: 'success',
      });
      handleClose();
    } catch (e) {
      log.error(e);
      enqueueSnackbar(`Could not save your changes. Refresh the page and try again.`, {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        variant="text"
        color="secondary"
        onClick={handleClickOpen}
        sx={{
          [theme.breakpoints.up('md')]: {
            ...styles.language,
          },
          marginTop: 0.45,
        }}
      >
        <Tooltip title="Change Currency" placement="top">
          <IconButton aria-label={language?.name} size="small" sx={{ color: isDesktop ? '#EEE' : '#000' }}>
            <CurrencyExchange fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Select your currency
        </BootstrapDialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 120, mt: '1rem' }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Currency</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCurrency}
                label="Currency"
                onChange={handleChangeCurrency}
              >
                {currencies.map((c) => (
                  <MenuItem key={c.name} value={c.name}>
                    {c.symbol} - {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button autoFocus onClick={handleSelection}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default LanguagePicker;
