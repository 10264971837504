'use client';

import { useEffect } from 'react';
// note that you can manually import Tracker class if you want to handle the instance manually
import { tracker } from '@openreplay/tracker';
import PropTypes from 'prop-types';
import { NEXT_PUBLIC_ENV } from 'constants/runtimeConfig';
import { ENVIRONMENTS } from 'constants/environments';

tracker.configure({
  projectKey: 'aGtxGqExi6hCIExQc49Q',
  __DISABLE_SECURE_MODE: NEXT_PUBLIC_ENV === ENVIRONMENTS.DEVELOPMENT,
  respectDoNotTrack: false,
  obscureTextEmails: false,
  obscureTextNumbers: false,
  obscureInputEmails: false,
});

const isTrackerEnabled = NEXT_PUBLIC_ENV === ENVIRONMENTS.PRODUCTION;

const Openreplay = ({ userDetails }) => {
  useEffect(() => {
    if (isTrackerEnabled && typeof window !== 'undefined') {
      tracker.start();
    }
  }, []);

  useEffect(() => {
    if (userDetails && userDetails.email) {
      tracker.setUserID(userDetails.id);
      tracker.setMetadata('email', userDetails.email);
      tracker.setMetadata('shippingCountry', userDetails.shippingCountry);
    }
  }, [userDetails]);

  return null;
};

Openreplay.propTypes = {
  userDetails: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    displayName: PropTypes.string,
    shippingCountry: PropTypes.string,
    paymentDays: PropTypes.number,
    creditLimit: PropTypes.number,
  }).isRequired,
};

export default Openreplay;
